import React, { useEffect, useState, useRef } from 'react'
import classNames from 'classnames'
import Downshift from 'downshift'
import FetchLocation from './UserLocationFetcher'
// import PropTypes from 'prop-types'

// TODO: PropTypes
const LOCATE_ENDPOINT = '/locate'
const UserLocation = () => {
  const renderInput = ({selectedItem, getInputProps, openMenu, clearSelection, isOpen}) => {
    if (selectedItem) {
      return (
        <div className='d-flex justify-content-between p-2 border-0 spree-flat-select text-primary'>
          {selectedItem.displayName}
          <button
            onClick={clearSelection}
            type='button' className='close'
            aria-label='clear selection'
          ><span aria-hidden='true'>&times;</span></button>
        </div>
      )
    } else {
      return (
        <input {...getInputProps({
          className: classNames('p-2 spree-flat-select', { 'rounded-bottom-0 border-bottom-0': isOpen }),
          placeholder: 'Enter your location',
          onFocus: () => openMenu(),
        })} />
      )
    }
  }

  const renderMenuItems = ({getItemProps, isOpen, inputValue, highlightedIndex, selectedItem}) => {
    if (!isOpen) return null
    return (
      <FetchLocation url={LOCATE_ENDPOINT} params={{q: inputValue}}>
        {({loading, error, data}) => {
          if (loading) {
            return <li className='dropdown-item' disabled>Loading...</li>
          }
          if (error) {
            return <li className='dropdown-item' disabled>{error}</li>
          }
          if (!data || !data.length) {
            return <li className='dropdown-item' disabled>Cannot locate you, sorry!</li>
          }
          return data.map((item, index) => (
            <li
              className={classNames('dropdown-item rounded-bottom p-2', { 'selected': highlightedIndex === index })}
              key={`location-option-${index}`}
              {...getItemProps({
                item,
                index,
              })}
            >
              {item.displayName}
            </li>
          ))
        }}
      </FetchLocation>
    )
  }
  return (
    <Downshift
      itemToString={item => (item ? item.displayName : '')}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        getRootProps,
        clearSelection,
        openMenu
      }) => (
        <div className='dropdown'>
          <div {...getRootProps({}, {suppressRefError: true})}>
            {renderInput({selectedItem, getInputProps, openMenu, clearSelection, isOpen})}
          </div>
          <ul
            className={classNames('dropdown-menu w-100 pointer', { 'show': isOpen })}
            {...getMenuProps()}
          >
            {renderMenuItems({getItemProps, isOpen, inputValue, selectedItem, highlightedIndex})}
          </ul>
        </div>
      )}
    </Downshift>
  )
}
export default UserLocation