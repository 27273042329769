import React, { useEffect, useState } from 'react'
import debounce from 'debounce-fn'
import usePrevious from '../helpers/usePrevious'

const FetchLocation = (props) => {

  // TODO: the initial data doesn't actually get persisted once this component has been unmounted,
  // so it's currently absolutely useless

  const [initData, setInitData] = useState(null)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const {children, url, params} = props
  const prevParams = usePrevious(params);

  // to signal that the component is mounted
  let isSubscribed = true

  useEffect(() => {
    if (!prevParams || params['q'] !== prevParams['q']) {
      fetchLocations()
    }
    return () => isSubscribed = false
  }, [params])

  const makeNetworkRequest = debounce((searchParams, callback = null) => {
    fetch(`${url}?${searchParams}`)
      .then((res) => res.json())
      .then((data) => {
        // bail if already unmounted
        if (!isSubscribed) return
        setData(data)
        setError(null)
        setLoading(false)
        if (callback) {
          callback(data)
        }
      })
      .catch(error => {
        setData(null)
        setError(error.message)
        setLoading(false)
      })
  }, { wait: 250 })

  const fetchLocations = () => {

    setError(null)
    setLoading(true)

    const hasParams = Object.values(params).some(val => val && val.length > 2)

    if (!hasParams) {

      if (!initData) {
        makeNetworkRequest('', (data) => {
          setInitData(data)
        })
      } else {
        setData(initData)
        setError(null)
        setLoading(false)
      }
    } else {
      // { q: "something", lat: "x" } => '?q=something&lat=x'
      const searchParams = Object.entries(params).map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      makeNetworkRequest(searchParams)
    }
  }

  return children({
    data,
    loading,
    error,
  })
}

export default FetchLocation